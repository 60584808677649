export const PreviewIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      {...props}
    >
      <path
        d="M3.13334 6.21772V14.1927C3.13334 15.8261 4.90833 16.8511 6.325 16.0344L9.78333 14.0427L13.2417 12.0427C14.6583 11.226 14.6583 9.18437 13.2417 8.36771L9.78333 6.36771L6.325 4.37606C4.90833 3.55939 3.13334 4.57605 3.13334 6.21772Z"
        stroke="#016FF0"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.8667 15.351V5.05103"
        stroke="#016FF0"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const ArcadiaPointsIcon = ({ isAsked }: { isAsked?: boolean }) => {
  if (isAsked)
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
      >
        <circle
          cx="10.8762"
          cy="11.048"
          r="9.81561"
          fill="#016FF0"
          stroke="#016FF0"
        />
        <mask
          id="mask0_3986_6550"
          maskUnits="userSpaceOnUse"
          x="1"
          y="1"
          width="20"
          height="20"
        >
          <circle cx="10.876" cy="11.0479" r="9.18414" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_3986_6550)">
          <path
            d="M12.4924 7.61599L12.4916 7.61682C11.5521 6.54843 10.1808 5.86901 8.65052 5.85096C5.76531 5.81687 3.41512 8.11774 3.39081 11.003C3.36651 13.8938 5.70266 16.245 8.58788 16.245C11.0732 16.245 13.1511 14.5005 13.6638 12.1691L16.4471 14.9524C16.6349 15.1402 16.9393 15.1402 17.1271 14.9524L18.138 13.9415C18.3258 13.7537 18.3258 13.4493 18.138 13.2615L12.4924 7.61599ZM8.58788 13.8538C7.03815 13.8538 5.78194 12.5976 5.78194 11.0478C5.78194 9.49806 7.03827 8.24186 8.58788 8.24186C10.1376 8.24186 11.3938 9.49818 11.3938 11.0478C11.3938 12.5976 10.1376 13.8538 8.58788 13.8538Z"
            fill="#E9EDF1"
          />
          <path
            d="M14.6619 9.06564L15.7642 10.168C15.9161 10.3198 16.1622 10.3198 16.3139 10.168L18.2478 8.23415C18.3996 8.08232 18.3996 7.83622 18.2478 7.6845L17.1454 6.58214C16.9936 6.4303 16.7475 6.4303 16.5957 6.58214L14.6619 8.51599C14.5102 8.66783 14.5102 8.91393 14.6619 9.06564Z"
            fill="#E9EDF1"
          />
        </g>
      </svg>
    );
  return (
    <svg
      width="22"
      height="21"
      viewBox="0 0 22 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="10.876"
        cy="10.387"
        r="9.816"
        fill="#D8D8D8"
        stroke="#CBCBCB"
      />
      <mask
        id="a"
        maskUnits="userSpaceOnUse"
        x="1"
        y="1"
        width="20"
        height="19"
      >
        <circle cx="10.876" cy="10.387" r="9.184" fill="#D9D9D9" />
      </mask>
      <g mask="url(#a)" fill="#fff">
        <path d="M12.492 6.955v.001A5.184 5.184 0 0 0 8.65 5.19a5.187 5.187 0 0 0-5.26 5.152 5.197 5.197 0 0 0 10.273 1.166l2.783 2.784a.48.48 0 0 0 .68 0l1.011-1.011a.48.48 0 0 0 0-.68l-5.646-5.646Zm-3.904 6.238a2.806 2.806 0 1 1 0-5.612 2.806 2.806 0 0 1 0 5.612Zm6.074-4.788 1.102 1.102a.389.389 0 0 0 .55 0l1.934-1.933a.389.389 0 0 0 0-.55L17.145 5.92a.389.389 0 0 0-.55 0l-1.933 1.934a.389.389 0 0 0 0 .55Z" />
      </g>
    </svg>
  );
};

export const BackIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      {...props}
    >
      <path
        d="M11.9917 14.9401L7.1017 10.0501C6.5242 9.47256 6.5242 8.52756 7.1017 7.95006L11.9917 3.06006"
        stroke="#717171"
        strokeWidth="1.5"
        stroke-miterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
