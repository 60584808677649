import { AdminLoginType, PlanType, PreviewPromptType, ProfileType, PromptType, ReviewTemplateType, presignedUrlType } from "../../shared/utilities/Types/types";
import api, { notificationApi } from "./interceptor";

export const adminLogin = async (data: AdminLoginType) => {
  try {
    const response = await api.post("/user/login", data);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const getSubscriptionPlans = async (page: number, limit: number, search?: string) => {
  try {
    let url = `/subscription/getPlans?page=${page}&limit=${limit}`;
    if (search) {
      url += `&search=${search}`;
    }
    const response = await api.get(url);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const createPlan = async (data: PlanType) => {
  try {
    const response = await api.post("/subscription/createPlan", data);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const updatePlan = async (id: string, data: PlanType) => {
  try {
    const response = await api.put(`/subscription/updatePlan/${id}`, data);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const deletePlan = async (id: string) => {
  try {
    const response = await api.delete(`/subscription/deletePlan/${id}`);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const getProfileDetails = async () => {
  try {
    const response = await api.get(`/user/userProfileDetails`);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const updateProfileDetails = async (data: ProfileType) => {
  try {
    const response = await api.patch(`/user/updateUserProfile`, data);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const getSubscriberList = async (page: number, limit: number, status: string) => {
  try {
    let url = `/subscription/getSubscriberList?page=${page}&limit=${limit}`;
    if (status) {
      url += `&status=${status}`;
    }
    const response = await api.get(url);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const getSubscriberListById = async (id: string) => {
  try {
    let url = `/subscription/subscriptionDetails/${id}`;
    const response = await api.get(url);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const getUsersCount = async () => {
  try {
    const response = await api.get(`/subscription/getUsersCount`);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const getStripeTransactionList = async (page: number, limit: number, userId: string, status: string) => {
  try {
    let url = `/payment/GetUserStripeTransactionList?page=${page}&limit=${limit}`;
    if (status) {
      url += `&paymentStatus=${status}`;
    }
    if (userId) {
      url += `&userId=${userId}`;
    }
    const response = await api.get(url);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const getGptPrompts = async (page: number, limit: number) => {
  try {
    let url = `/admin/getGptPrompts?page=${page}&limit=${limit}`;
    const response = await api.get(url);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const getGptPromptsById = async (id: string) => {
  try {
    let url = `/admin/getGptPromptById?promptKey=${id}`;
    const response = await api.get(url);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const updatePrompt = async (data: PromptType) => {
  try {
    const response = await api.put(`/admin/editGptPromptById`, data);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const getTemplateRequests = async (page: number, limit: number) => {
  try {
    let url = `/meeting/getTemplatesRequests?page=${page}&limit=${limit}`;
    const response = await api.get(url);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const getTemplateRequestsById = async (templateId: string) => {
  try {
    let url = `/meeting/getTemplateRequest/${templateId}`;
    const response = await api.get(url);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const reviewTemplate = async (data: ReviewTemplateType) => {
  try {
    const response = await api.post(`/meeting/reviewTemplate`, data);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const getUserSubscriptionStats = async (params: any) => {
  try {
    let url = `/subscription/getUserSubscriptionStats${params}`;
    const response = await api.get(url);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const previewPrompt = async (data: PreviewPromptType) => {
  try {
    const response = await api.post(`/admin/previewEditPrompt`, data);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const generatePresignedUrl = async (data: presignedUrlType) => {
  try {
    const response = await api.get(`/presigned-url`, {
      params: data,
    });
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const getNotifications = async () => {
  try {
    const response = await notificationApi.get(`/notifications/push-notifications/get-unread-push-notifications`);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const markAsReadNotification = async (notificationId: string) => {
  try {
    const response = await notificationApi.patch(`/notifications/push-notifications/read-receipts/${notificationId}`);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const adminLogout = async () => {
  try {
    const response = await api.post(`user/logoutUser`);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const getWaitlist = async (page: number, limit: number, status: string) => {
  try {
    let url = `user/waitlist?page=${page}&limit=${limit}`;
    if (status) {
      url += `&status=${status}`;
    }
    const response = await api.get(url);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const updateWaitlistStatus = async (payload: any) => {
  try {
    const response = await api.patch(`user/waitlist`, payload);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const getCalenderSyncedUsers = async (page: number, limit: number) => {
  try {
    const response = await api.get(`meeting/calendarsync?page=${page}&limit=${limit}`);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};
export const updateCalenderSync = async (payload) => {
  try {
    const response = await api.patch(`meeting/calendarsync`, payload);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};
