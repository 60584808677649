import { createContext, useContext, useReducer } from "react";
import { getProfileDetails, updateProfileDetails } from "../apis/api";
import { useNavigate } from "react-router";

const UserContext = createContext(null);

const initialState = {
  user: null,
};

const userReducer = (state, action) => {
  switch (action.type) {
    case "SET_USER":
      return { ...state, user: action.payload };
    default:
      return state;
  }
};

const UserProvider = ({ children }) => {
  const [state, dispatch] = useReducer(userReducer, initialState);
  const getUserData = async () => {
    try {
      const response = await getProfileDetails();
      dispatch({ type: "SET_USER", payload: response });
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const updateUser = async (userData) => {
    try {
      const response = await updateProfileDetails(userData); // Replace with your API endpoint
      dispatch({ type: "SET_USER", payload: response });
    } catch (error) {
      console.error("Error updating user data:", error);
    }
  };

  return <UserContext.Provider value={{ user: state.user, getUserData, updateUser }}>{children}</UserContext.Provider>;
};

const useUserContext = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUserContext must be used within a UserProvider");
  }
  return context;
};

export { UserProvider, useUserContext };
